import * as React from "react";
import { render } from "react-dom";

import { Testimonials } from "./components/testimonial/Testimonial";

const testimonials = [
  {
    name: "John",
    stars: 5,
    comment:
      "<p>Nous avons été très satisfaits de la prestation.<br />La présentation était impeccable. Et la nourriture excellente. <br />Tous les invités se sont régalés et m'en ont reparlé.</p><p>Un grand bravo et merci !</p>",
  },
  {
    name: "Paul",
    stars: 5,
    comment:
      "J’ai goûté à l’occasion d’une journée découverte, et c’est d’abord la qualité des viandes, et le goût vraiment spécial des préparations fumées des heures, qui m’a surpris et convaincu.",
  },
  {
    name: "Jacques",
    stars: 5,
    comment:
      "Organisation parfaite et des plats de qualité ! Nous avons été ravis de ce concept pour notre soirée.",
  },
];

render(
  <Testimonials testimonials={testimonials} slideTs={1000}></Testimonials>,
  document.querySelector(".testimonial")
);


